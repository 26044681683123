'use client';

import {
  BankOutlined,
  BetTransactionFilled,
  LockRerollOutlined,
  PaperInfoOutlined,
  ProfileFilled,
} from '@packages/icons-react';
import { useTranslation } from 'react-i18next';
import DynamicMenu from '~components/widgets/dynamic-menu';
import PageUrls from '~constants/page-urls';
import { useRouter } from '~hooks/use-router';

const UserMenu: React.FC = () => {
  const router = useRouter();
  const { t } = useTranslation('common');

  const menuData = [
    {
      name: t('bet-transactions'),
      icon: <BetTransactionFilled />,
      action: PageUrls.BetTransactionCasino,
    },
    {
      name: t('profile'),
      icon: <ProfileFilled />,
      action: PageUrls.Account,
    },
    {
      name: t('bank-account'),
      icon: <BankOutlined />,
      action: PageUrls.PaymentMethods,
    },
    {
      name: t('financial-transaction'),
      icon: <PaperInfoOutlined />,
      action: PageUrls.FinancialTransaction,
    },
    {
      name: t('change-password'),
      icon: <LockRerollOutlined />,
      action: PageUrls.ChangePassword,
    },
  ];

  const handleChangeMenu = (action: string): void => {
    router.push(action);
  };

  return (
    <DynamicMenu
      isArrow={false}
      itemActive=""
      items={menuData}
      onChange={handleChangeMenu}
    />
  );
};

export default UserMenu;
