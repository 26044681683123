import { cn } from 'class-merge';
import Image from 'next/image';
import type { FC } from 'react';
import { useMemo, useState } from 'react';
import type { GameFeatureListsResApi } from 'types/provider';
import type { ProviderGamingListResApi } from 'types/gaming';
import { parseCookies } from 'nookies';
import { useTranslation } from 'react-i18next';
import {
  DEFAULT_CURRENCY,
  ProviderTypeEnum,
  S3_BUCKET_IMAGES,
} from '~constants/etc';
import { useGameFeature } from '~hooks/use-providers';
import getImageLists from '~libs/get-image-list';
import HttpErrorHandler from '~components/http-error-handler';
import PageUrls from '~constants/page-urls';
import { useRouter } from '~hooks/use-router';
import request from '~libs/request';
import objectToQueryString from '~libs/object-to-query-string';
import { encrypt } from '~libs/encrypt';
import { useUserInfo } from '~hooks/use-user';
import { useWalletInfo } from '~hooks/use-wallet';

type GameFeatureMappedType = (GameFeatureListsResApi & {
  bentoImage: string;
  className: string;
  iconClassName: string;
  imageClassName?: string;
  image: string;
  action: (providerId: string, gameCode?: string) => void;
})[];

const CustomImage: FC<{
  src: string;
}> = ({ src }) => {
  const [image, setImage] = useState(src);

  return (
    <Image
      alt="image"
      draggable={false}
      height={120}
      onError={() => {
        setImage(`${S3_BUCKET_IMAGES}/logo.webp`);
      }}
      src={image}
      width={120}
    />
  );
};

const PopularGames: FC = () => {
  const router = useRouter();
  const { t } = useTranslation(['game-categories']);
  const { data: userInfo } = useUserInfo();
  const { data: walletInfo } = useWalletInfo(
    userInfo?.selectedWallet.objectId || '',
  );
  const currency = walletInfo?.currency || DEFAULT_CURRENCY;
  const cookies = parseCookies();
  const locale = cookies.NEXT_LOCALE || '';
  const { data: gameFeature } = useGameFeature(currency);
  const [gameProverActive, setGameProverActive] = useState(
    ProviderTypeEnum.Casino,
  );

  const requestOpenGaming = async (id, gameCode): Promise<void> => {
    try {
      const providers = await request<ProviderGamingListResApi[]>({
        url: `/gaming`,
        method: 'GET',
        params: {
          currency,
        },
      });
      const providerValue = providers.find((res) => res.id === id);
      const currencyFiltered = providerValue?.currencies
        .filter((x) => x.includes(currency))
        .at(0);
      const queryString = objectToQueryString({
        category: ProviderTypeEnum.Gaming,
        providerId: providerValue?.objectId,
        gameCode,
        currency: currencyFiltered,
        walletId: walletInfo?.objectId,
      });
      router.push(`/${locale}${PageUrls.OpenGame}?qs=${encrypt(queryString)}`);
    } catch (e) {
      HttpErrorHandler(e);
    }
  };

  // const requestOpenCasino = async (id: string): Promise<void> => {
  //   const provider = casinoProvider?.find((res) => res.id === id);
  //   try {
  //     const currencyFiltered = provider?.currencies
  //       .filter((x) => x.includes(currency))
  //       .at(0);
  //     const queryString = objectToQueryString({
  //       category: ProviderTypeEnum.Casino,
  //       providerId: id,
  //       currency: currencyFiltered,
  //       walletId: walletInfo?.objectId,
  //     });
  //     router.push(`/${locale}${PageUrls.OpenGame}?qs=${encrypt(queryString)}`);
  //   } catch (e) {
  //     HttpErrorHandler(e);
  //   }
  // };

  // const requestOpenSportsbook = async (id: string): Promise<void> => {
  //   const currencyFiltered = sportsbookProvider?.[0]?.currencies
  //     .filter((x) => x.includes(currency))
  //     .at(0);
  //   try {
  //     const response = await onOpenSportURL(
  //       String(id),
  //       currencyFiltered,
  //       walletInfo?.objectId,
  //     );
  //     window.open(response, '_blank');
  //   } catch (e) {
  //     HttpErrorHandler(e);
  //   }
  // };

  const handleOpenProvider = (id: string, gameCode?: string): void => {
    if (gameCode) {
      void requestOpenGaming(id, gameCode);
    } else {
      router.push(`${PageUrls.Casino}/${id}`);
      // void requestOpenCasino(id);
    }
  };

  const handleOpenGaming = (id: string): void => {
    router.push(`${PageUrls.Gaming}/${id}`);
  };

  const handleOpenGame = (id: string, gameCode?: string): void => {
    handleOpenProvider(id, gameCode);
  };

  const handleOpenSportsbook = (id: string): void => {
    router.push(`${PageUrls.Sportsbook}/${id}`);
    // void requestOpenSportsbook(id);
  };

  const actionFunction = {
    [ProviderTypeEnum.Casino]: handleOpenProvider,
    [ProviderTypeEnum.Gaming]: handleOpenGaming,
    [ProviderTypeEnum.Game]: handleOpenGame,
    [ProviderTypeEnum.Sportsbook]: handleOpenSportsbook,
  };

  const categoryMapped: Record<string, GameFeatureMappedType> = useMemo(() => {
    const defaultFGameFeatureLists = {
      // [ProviderTypeEnum.Game]: [] as GameFeatureMappedType,
      [ProviderTypeEnum.Casino]: [] as GameFeatureMappedType,
      [ProviderTypeEnum.Gaming]: [] as GameFeatureMappedType,
      [ProviderTypeEnum.Sportsbook]: [] as GameFeatureMappedType,
    };
    if (!gameFeature) {
      return defaultFGameFeatureLists;
    }
    return gameFeature.reduce((accum, item) => {
      const imagesLists = getImageLists(item.images);
      const itemProps = {
        image: imagesLists.horizontalLogo,
      };
      item.category = item.gameCode ? ProviderTypeEnum.Game : item.category;
      if (!accum[item.category]) {
        return accum;
      }
      accum[item.category].push({
        ...item,
        ...itemProps,
      });
      return accum;
    }, defaultFGameFeatureLists);
  }, [gameFeature]);

  if (!categoryMapped[gameProverActive].length) {
    return;
  }

  return (
    <div className="my-16 flex flex-col items-center px-10 xl:px-[100px]">
      <h3 className="text-primary text-[40px] font-bold">
        {t('game-categories:popular-game')}
      </h3>
      <div className="mt-6 flex w-full justify-center gap-x-2">
        {Object.keys(categoryMapped).map((item) => {
          if (item === ProviderTypeEnum.Lotto) return null;
          return (
            <button
              className={cn(
                'bg-dark h-[40px] w-full max-w-[200px] rounded-2xl border border-gray-600 px-8 py-1 text-center capitalize',
                'cursor-pointer',
                gameProverActive === item ? 'bg-primary' : '',
              )}
              key={item}
              onClick={() => {
                setGameProverActive(item as ProviderTypeEnum);
              }}
            >
              {t(`game-categories:${item}` as any)}
            </button>
          );
        })}
      </div>
      <div className="flex w-full">
        <div className="mt-8 grid grid-cols-6 gap-2 xl:grid-cols-8">
          {categoryMapped[gameProverActive]?.map((res) => {
            return (
              <div
                aria-hidden
                className="hover:ring-primary to-dark/10 flex h-[140px] w-[140px] flex-col items-center justify-between rounded-xl bg-gradient-to-t from-black p-4 hover:ring-2"
                key={res.providerId}
                onClick={() => {
                  actionFunction[res.category](res.providerId, res.gameCode);
                }}
                role="button"
              >
                <div className="flex h-[80px] items-center justify-center">
                  <CustomImage src={res.image} />
                </div>
                <div className="w-full truncate text-center text-sm">
                  {res.gameName || res.providerName}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default PopularGames;
