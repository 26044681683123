import type { FC } from 'react';
import { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { cn } from 'class-merge';
import Image from 'next/image';
import getImageLists from '~libs/get-image-list';
import {
  NEXT_PUBLIC_S3_BUCKET_ASSETS_URL,
  S3_BUCKET_IMAGES,
} from '~constants/etc';

const ImageRender: FC<{
  alt: string;
  src: string;
}> = ({ alt, src }) => {
  const [image, setImage] = useState(`${S3_BUCKET_IMAGES}/logo.webp`);

  useEffect(() => {
    if (src) {
      setImage(src);
    }
  }, [src]);

  return (
    <Image
      alt={alt}
      draggable={false}
      height={50}
      onError={() => {
        setImage(`${S3_BUCKET_IMAGES}/logo.webp`);
      }}
      src={image}
      unoptimized
      width={125}
    />
  );
};

const AllProviderContainer: FC<{
  providers: any[];
  onSelectProvider: (provider: any) => void;
}> = ({ providers, onSelectProvider }) => {
  const { t } = useTranslation();

  const providersMapped = useMemo(() => {
    if (!providers) {
      return [];
    }
    return providers.map((provider) => {
      const images = getImageLists(provider?.images || []);
      return {
        ...provider,
        logo: `${NEXT_PUBLIC_S3_BUCKET_ASSETS_URL}/production/media/image/${provider.id}/logo/${provider.id}-horizontal-logo.png`,
      };
    });
  }, [providers]);

  if (!providersMapped.length) return null;

  return (
    <div
      className={cn(
        'flex h-full w-full flex-col justify-center space-y-8 bg-cover bg-center bg-no-repeat px-10 py-10 xl:px-[100px]',
      )}
      style={{
        backgroundImage: `url(${S3_BUCKET_IMAGES}/all-provider-bg.webp)`,
      }}
    >
      <div className="relative mb-4 capitalize">
        <h2 className=" text-3xl font-bold text-white">
          {t('common:choose-by-provider')}
        </h2>
        <div className="bg-primary absolute -bottom-1 h-0.5 w-10" />
      </div>
      <div className="grid h-full gap-4 md:grid-cols-4 xl:grid-cols-6">
        {providersMapped.map((provider) => (
          <button
            className="bg-secondary hover:border-primary relative flex h-[100px] w-full max-w-[300px] cursor-pointer items-center justify-center overflow-hidden rounded-2xl border-transparent hover:border-2"
            key={`${provider.id}-provider`}
            onClick={() => {
              onSelectProvider(provider);
            }}
          >
            <div className="bg-primary absolute -left-1 h-8 w-2 rounded-lg" />
            <ImageRender alt={provider.title} src={provider.logo} />
            <div className="bg-primary absolute -right-1 h-8 w-2 rounded-lg" />
          </button>
        ))}
      </div>
    </div>
  );
};

export default AllProviderContainer;
