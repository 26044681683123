const PageUrls = {
  Home: '/',
  Login: '/login',
  Register: '/register',
  ForgotPassword: '/forgot-password',
  Affiliate: '/affiliate',
  AffiliateMember: '/affiliate/member',
  EnableAffiliate: '/affiliate/enable-referral',
  Bonus: '/bonus',
  Profile: '/profile',
  Contact: '/contact',
  Account: '/account',
  PaymentMethods: '/payment-methods',
  FinancialTransaction: '/financial-transaction',
  ChangePassword: '/change-password',
  FinancialDeposit: '/deposit',
  FinancialDepositPromotion: '/promotion',
  FinancialWithdraw: '/withdraw',
  BetTransactionCasino: '/bet-transactions/casino',
  BetTransactionGaming: '/bet-transactions/gaming',
  BetTransactionSportsbook: '/bet-transactions/sportsbook',
  Announcement: '/announcement',
  OpenGame: '/open-game',
  Casino: '/casino',
  Gaming: '/gaming',
  Sportsbook: '/sportsbook',
};

export default PageUrls;
