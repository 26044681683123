import type {
  ProviderCasinoResApiInterface,
  ResponseApiCasinoBetTransactionInterface,
} from 'types/casino';
import { useCallback } from 'react';
import { objectify } from 'radash';
import type { KeyedMutator } from 'swr';
import type { AxiosResponse, Method } from 'axios';
import type { Config } from './use-request';
import useRequest from './use-request';

export const useCasinos = (
  currency?: string,
  config?: Config<ProviderCasinoResApiInterface[]>,
) => {
  return useRequest<ProviderCasinoResApiInterface[]>(
    {
      url: '/casinos',
      params: {
        currency,
      },
    },
    {
      revalidateOnFocus: true,
      ...config,
    },
  );
};

export const useGameListInCasinoProvider = (
  currency?: string,
  provider?: string,
  config?: Config<any[]>,
): {
  data: any[] | undefined;
  isLoading: boolean;
  mutate: KeyedMutator<AxiosResponse<any[]>>;
} => {
  return useRequest<any[]>(
    provider
      ? {
          method: 'GET' as Method,
          url: `/casinos/${provider}/games`,
          params: {
            currency,
          },
        }
      : null,
    config,
  );
};

export const useCasinoBetTransactions = (
  query: any = {},
  config?: Config<ResponseApiCasinoBetTransactionInterface>,
) => {
  return useRequest<ResponseApiCasinoBetTransactionInterface>(
    {
      url: '/casinos/bet-transactions',
      params: {
        startAt: query.startAt,
        endAt: query.endAt,
        provider: query.provider,
      },
    },
    { ...config, revalidateOnFocus: true },
  );
};

export const useCasinosInfoHelper = (currency: string) => {
  const { data } = useCasinos(currency);
  const getCasinoProviderImage = useCallback(
    (provider: string) => {
      const casinoProvider = data?.find((i) => i.id === provider);
      if (!casinoProvider) {
        return;
      }
      return objectify(
        casinoProvider?.images,
        (f) => f.name as string,
        (f) => f.value as string,
      );
    },
    [data],
  );
  return {
    getCasinoProviderImage,
  };
};
